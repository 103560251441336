import sha256 from 'crypto-js/sha256';
import Base64 from 'crypto-js/enc-base64';
import config from '../../api/config';

export const generateCodeChallenge = (code_verifier) => {
  return sha256(code_verifier);
};


export const generateAuthConfig = (data, featureFlags) => {
  const {
    code_verifier,
    code_challenge
  } = generate_code_challenge_verifier_pair();
  localStorage.setItem('code_verifier', code_verifier);
  return {
    client_id: 'capioWeb',
    redirect_uri: `${config.redirectBase}/callback`,
    scope: 'capioScope profile openid offline_access',
    response_type: 'code',
    code_challenge_method: 'S256',
    code_challenge: code_challenge,
    state: state_generator(data),
    acr_values: "idp:xenit_eid_2"
  };
};

const state_generator = (data='') => {
  let state_verifier = sha256(uuidv4_generator());
  localStorage.setItem('state_verifier', state_verifier);
  return btoa(JSON.stringify({
    state_verifier: state_verifier,
    additional_parameters: data ? data : ''
  }))
};

const uuidv4_generator = () => {
  return 'xxxxxxxxxxxxxxxxyxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxyxxxxxxxxxxxxxxx'.replace(
    /[xy]/g,
    c => {
      let r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    }
  );
};

export const generate_code_challenge_verifier_pair = () => {
  let code_verifier = uuidv4_generator();
  let hash = generateCodeChallenge(code_verifier);
  let code_challenge = Base64.stringify(hash)
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
  return { code_verifier, code_challenge };
};