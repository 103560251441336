import NetworkError from "../NetworkError";
import config from "../config";
import type { AxiosInstance } from "axios";

type FeatureConfigResponse = {
  settings: any[],
  features: [
    { key: "closeAccountV2", value: boolean },
    { key: "findHealthcareUnits", value: boolean },
    { key: "piwikProEnabled", value: boolean },
    { key: "smsValidation", value: boolean },
    { key: "useService2Dark", value: boolean }
  ]
}

type ResponseToConfig<T extends { key: string; value: boolean }[]> = {
  [U in T[number] as U['key']]: U['value'];
};

export type FeatureConfig = ResponseToConfig<FeatureConfigResponse["features"]>

export type FeaturesApi = InstanceType<typeof FeaturesAPI>

export class FeaturesAPI {
  private _http: AxiosInstance
  private _baseUrl: string
  
  constructor(http: AxiosInstance) {
    this._http = http;
    this._baseUrl = config.featureConfig;
  }

  async fetchFeatureConfig() {
    if (!this._baseUrl) {
      return {}
    }

    try {
      const res = await this._http.get<FeatureConfigResponse>(this._baseUrl);

      if (res.status !== 200) {
        throw new NetworkError(res.status, res.data);
      }

      const result = res.data["features"].reduce(
        (acc, curr) => ({ 
          ...acc, 
          [curr["key"]]: curr["value"] 
        }), 
        {}
      ) as FeatureConfig

      return result
    } catch (e) {
      console.error(e);

      return {}
    }
  }
}
