import { Component } from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'
import { actions as appActions } from '../../store/reducers/app'
import { actions as accountActions } from '../../store/reducers/accounts'
import { actions as tosActions } from "src/store/reducers/terms-of-services"
import { paths, createPath } from '../../utils/urlPaths'
import config from '../../api/config'
import { encapsulate } from '../../utils/stringUtils'
import { LoadingScreen } from '../../new-lib/LoadingScreen/LoadingScreen'
import { FixMeLater } from 'src/new-lib/_types'
import type { RootState } from 'src/store/Model'

type Props = {
  dispatch: FixMeLater
  history: FixMeLater
}

class Callback extends Component<Props> {
  async componentDidMount () {
    const dispatch = this.props.dispatch
    const history = this.props.history
    const urlQueryParams = queryString.parse(history.location.search)

    dispatch(appActions.saveCode(urlQueryParams.code))
    dispatch(appActions.saveRedirectURI(`${config.redirectBase}/callback`))

    try {
      await dispatch(appActions.createSessionToken())
            
      this.handleFetchUserInfo(urlQueryParams)
    } catch (error) {
      history.push(paths["login"])
    }
  }
  
  async handleFetchUserInfo(urlQueryParams) {
    const dispatch = this.props.dispatch
    const history = this.props.history
    const state = JSON.parse(atob(urlQueryParams.state))
    
    try {
      const isRegistered = await dispatch(accountActions.fetUserInfo())
      
      if (isRegistered) {
        dispatch(appActions.userIsRegistered())

        await Promise.all([dispatch(tosActions.fetchLatestTos()), dispatch(tosActions.fetchTosStatus())])
      }

      if (state.additional_parameters.fast_listing) {
        history.push({
          pathname: createPath({ name: "fast listing > sign (hsaId)", params: [state.additional_parameters.hsa_id] }),
          search: `?fastListing=${state.additional_parameters.fast_listing}&hsaid=${state.additional_parameters.hsa_id}`
        })
        return
      }

      if (state.additional_parameters.next_route) {
        history.push({
          pathname: paths["home"],
          search: `?service=${state.additional_parameters.next_route}`
            + `&hid=${encapsulate(state.additional_parameters.hcu_id)}`
            + `&de=${encapsulate(state.additional_parameters.direct_entry)}`
        })
      } else {
        history.push({ pathname: paths["home"] })
      }

    } catch (error) {
      history.push({
        pathname: paths["registration > ToS"],
        search: `?service=${state.additional_parameters.next_route}`
          + `&hid=${encapsulate(state.additional_parameters.hcu_id)}`
          + `&de=${encapsulate(state.additional_parameters.direct_entry)}`
      })
    }
  }

  render () {
    return <LoadingScreen />
  }
}

export const CallbackPage = connect((state: RootState) => {
  return {
  }
})(Callback)
