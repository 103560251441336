import { AxiosStatic } from "axios";
import CenterAPI from "./services/CenterAPI";
import { AppAPI } from "./services/AppAPI";
import { LocationAPI } from "./services/LocationAPI";
import { ListingAPI } from "./services/ListingAPI";
import { NewsletterAPI } from "./services/NewsletterAPI";
import { TosAPI, type TosApi } from "./services/TermsOfServiceAPI";
import { FeaturesAPI, type FeaturesApi } from "./services/FeaturesApi";
import { PhoneValidationAPI, type PhoneValidationApi } from "./services/PhoneValidationAPI";
import { ContentAPI, type ContentApi } from "./services/ContentAPI";
import { AccountAPI, type AccountApi } from "./services/AccountAPI";
import { UnsubscribeAPI, type UnsubscribeApi } from "./services/UnsubscribeAPI";
import { ConfigrationAPI, type ConfigrationApi } from "./services/ConfigurationAPI";

export type RootApi = InstanceType<typeof Api>

export default class Api {
  private sessionToken: string
  public onError: (error: unknown) => void
  public services: {
    app: InstanceType<typeof AppAPI>
    center: InstanceType<typeof CenterAPI>
    location: InstanceType<typeof LocationAPI>
    listing: InstanceType<typeof ListingAPI>
    newsletter: InstanceType<typeof NewsletterAPI>
    termsOfServices: TosApi
    features: FeaturesApi
    phoneValidation: PhoneValidationApi
    content: ContentApi
    account: AccountApi
    unsubscribe: UnsubscribeApi
    configuration: ConfigrationApi
  }

  constructor(http: AxiosStatic) {
    const httpClient = http.create();

    httpClient.interceptors.request.use((config) => {
      if (this.sessionToken) {
        config.headers["Authorization"] = `Bearer ${this.sessionToken}`;
      }
      return config;
    });

    httpClient.interceptors.response.use(
      (response) => {
        // Injection point on response object

        return response;
      },
      (error) => {
        // Response object contains other than 2xx
        console.log("HTTP interceptor on error code: ", error);

        if (this.onError) {
          this.onError(error);
        }
        //localStorage.removeItem('sessionToken');
        return Promise.reject(error);
      }
    );

    /**
     * Alternative client used for strapi web requests.
     *
     * It does not share authentication token with rest of system,
     * and permission errors should not trigger logout.
     */
    const strapiClient = http.create();

    this.services = {
      app: new AppAPI(httpClient),
      center: new CenterAPI(strapiClient),
      location: new LocationAPI(strapiClient),
      listing: new ListingAPI(httpClient),
      newsletter: new NewsletterAPI(httpClient),
      termsOfServices: new TosAPI(httpClient),
      features: new FeaturesAPI(httpClient),
      phoneValidation: new PhoneValidationAPI(httpClient),
      content: new ContentAPI(strapiClient),
      account: new AccountAPI(httpClient),
      unsubscribe: new UnsubscribeAPI(httpClient),
      configuration: new ConfigrationAPI(httpClient)
    };
  }
}
