import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import { actions as consentActions } from '../../store/reducers/consents'
import { actions as appActions } from '../../store/reducers/app'
import { actions as accountActions } from '../../store/reducers/accounts'
import { actions as tosActions } from '../../store/reducers/terms-of-services'
import { paths } from '../../utils/urlPaths'
import { TermsOfServiceView } from './TermsOfServiceView'

const _RegistrationAcceptTosPage = ({
  dispatch,
  history,
  location,
  formIsFilled,
  termsOfService,
}) => {
  const [tosAccepted, setTosAccepted] = useState(false)
  const [formError, setFormError] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState()

  useEffect(() => {
    dispatch(tosActions.fetchLatestTos())
  }, [])

  const onTosAcceptedChange = (ev) => {
    setTosAccepted(ev.target.checked)

    if (ev.target.checked) {
      setFormError(false)
    }
  }

  const onSubmit = () => {
    if (!tosAccepted) {
      setFormError(true)
      setErrorMessage('Du måste godkänna tjänstevillkoren.')
      return
    }

    setIsSubmitting(true)
    
    return dispatch(accountActions.createUser())
      .then(() => {
        return dispatch(appActions.fetchMinimumUserState()).then(() => {
          history.replace({
            pathname: paths["home"],
            search: location.search
          })
          setIsSubmitting(true)
        })
      })
      .catch((e) => {
        setIsSubmitting(true)
        console.log('Registration error: ', e)
        return Promise.resolve()
      })
  }

  return formIsFilled
    ? (
      <TermsOfServiceView
        onTosAcceptedChange={onTosAcceptedChange}
        onClick={onSubmit}
        formError={formError}
        loading={isSubmitting}
        termsOfService={termsOfService}
        errorMessage={errorMessage}
        onClickBack={() => history.goBack()}
      />
      )
    : (
      <Redirect to={paths["registration > contact"]} />
      )
}

const mapStateToProps = (state) => ({
  formIsFilled: state.consent.myInformation.emailAddress && true,
  termsOfService: state.termsOfServices,
})

export const RegistrationAcceptTosPage = withRouter(
  connect(mapStateToProps, null)(_RegistrationAcceptTosPage)
)
