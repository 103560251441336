import { useEffect } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { paths } from "../../utils/urlPaths";
import { HomeView } from "./HomeView";
import { LoggedInLayout } from "../_layouts";
import { getDisplayName, getCareCentersSelector } from "../../store/selectors";
import queryString from "query-string";
import { actions as contentActions } from "../../store/reducers/content";
import { LoadingScreen } from "src/new-lib/LoadingScreen"

const Page = (props) => {
  useEffect(() => {
    props.dispatchFetchContent();
  }, []);

  const urlQueryParams = queryString.parse(props.location.search);
  const service = urlQueryParams.service || urlQueryParams.s;
  if (service === "listing") {
    props.history.push({
      pathname: paths["listing > select"],
      search: props.location.search,
    });
  }

  if (service === "capiogo" || service === "dhc") {
    props.history.push({
      pathname: paths["switchboard"],
      search: props.location.search,
    });
  }

  const { displayName, centers, savedCenterId, protectedId, tosStatus, content } = props;


  const getSavedCareCenterData = () => {
    if (!savedCenterId) return null;

    const careUnit = centers.find((i) => i.idExt === savedCenterId);
    return {
      careUnit: careUnit.healthcareunit,
      phoneNumber: careUnit.healthcareunitPhoneNumber,
      address: careUnit.healthcareunitAddress,
      postCode: careUnit.healthcareunitZipCode,
      city: careUnit.cityname,
      to: careUnit.healthcareunitWebAddress,
    };
  };

  return (
    <LoggedInLayout>
      {!props.userLoggedIn && <Redirect to={paths["logout"]} />}

      {props.userIsRegistered && !props.applicationGlobalLoading && (
        <HomeView
          content={content}
          displayName={displayName}
          savedCareCenterData={getSavedCareCenterData()}
          protectedId={protectedId}
        />
      )}

    </LoggedInLayout>
  );
};

const mapStateToProps = (state) => ({
  app: state.app,
  userLoggedIn: state.app.userLoggedIn,
  userIsRegistered: state.app.userIsRegistered,
  applicationGlobalLoading: state.app.applicationGlobalLoading,
  displayName: getDisplayName(state),
  centers: getCareCentersSelector(state),
  savedCenterId: state.consent.myInformation.primaryHealthCareEntityId,
  protectedId: state.consent.myInformation.protectedIdentity,
  content: state.content.home,
});


const mapDispatchToProps = (dispatch, { history }) => ({
  dispatchFetchContent: () => {
    dispatch(contentActions.fetchHome());
    dispatch(contentActions.fetchFlowOnboardingStart());
    dispatch(contentActions.fetchTos());
  },
});

export const HomePage = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Page)
);
