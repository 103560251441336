import { initialState, type ContentState } from "./contentState"
import { AxiosError } from "axios"
import type {
    FaqContent,
    FlowOnboardingStartContent,
    HomeContent,
    TopbarContent,
    ProfilePageContent,
    WebGlobalContent,
    TosContent,
    FooterContent,
    CareUnit,
    CareUnitsRegions
} from "src/api/services/ContentAPI"


type FAQActionArgs =
    { type: "fetch faq content" }
    | { type: "fetch faq content succeeded", payload: FaqContent }
    | { type: "fetch faq content failed", payload: AxiosError }

type HomeActionArgs =
    { type: "fetch home content" }
    | { type: "fetch home content succeeded", payload: HomeContent }
    | { type: "fetch home content failed", payload: AxiosError }

type FlowOnboardingStartActionArgs =
    { type: "fetch flow onboarding start content" }
    | { type: "fetch flow onboarding start content succeeded", payload: FlowOnboardingStartContent }
    | { type: "fetch flow onboarding start content failed", payload: AxiosError }

type TopbarActionArgs =
    { type: "fetch topbar content" } 
    | { type: "fetch topbar content succeeded", payload: TopbarContent }
    | { type: "fetch topbar content failed", payload: AxiosError }

type ProfileActionArgs =
    { type: "fetch profile content" }
    | { type: "fetch profile content succeeded", payload: ProfilePageContent }
    | { type: "fetch profile content failed", payload: AxiosError }

type GlobalActionArgs =
    { type: "fetch global content" }
    | { type: "fetch global content succeeded", payload: WebGlobalContent }
    | { type: "fetch global content failed", payload: AxiosError }

type TosActionArgs =
    { type: "fetch tos content" }
    | { type: "fetch tos content succeeded", payload: TosContent }
    | { type: "fetch tos content failed", payload: AxiosError }

type FooterActionArgs =
    { type: "fetch footer content" }
    | { type: "fetch footer content succeeded", payload: FooterContent }
    | { type: "fetch footer content failed", payload: AxiosError }

type CareUnitsActionArgs =
    { type: "fetch care units content" }
    | { type: "fetch care units content succeeded", payload: CareUnit[] }
    | { type: "fetch care units content failed", payload: AxiosError }

type CareUnitsRegionsActionArgs =
    { type: "fetch care units regions content" }
    | { type: "fetch care units regions content succeeded", payload: CareUnitsRegions[] }
    | { type: "fetch care units regions content failed", payload: AxiosError }


type ContentActionArgs =
    FAQActionArgs
    | HomeActionArgs
    | FlowOnboardingStartActionArgs
    | TopbarActionArgs
    | ProfileActionArgs
    | GlobalActionArgs
    | TosActionArgs
    | FooterActionArgs
    | CareUnitsActionArgs
    | CareUnitsRegionsActionArgs 

export type ContentDispatcher = (args: ContentActionArgs) => void

export const reducer = (state = initialState, action: ContentActionArgs): ContentState => {
    switch (action.type) {
        case "fetch faq content":
            return { ...state, faq: { isLoading: true } }
        case "fetch faq content succeeded":
            return { ...state, faq: { data: action.payload, isLoading: false } }
        case "fetch faq content failed":
            return { ...state, faq: { error: action.payload, isLoading: false } }


        case "fetch home content":
            return { ...state, home: { ...state.home, isLoading: true } }
        case "fetch home content succeeded":
            return { ...state, home: { data: action.payload, isLoading: false } }
        case "fetch home content failed":
            return { ...state, home: { error: action.payload, isLoading: false } }


        case "fetch flow onboarding start content":
            return { ...state, flowOnboardingStart: { isLoading: true } }
        case "fetch flow onboarding start content succeeded":
            return { ...state, flowOnboardingStart: { data: action.payload, isLoading: false } }
        case "fetch flow onboarding start content failed":
            return { ...state, flowOnboardingStart: { error: action.payload, isLoading: false } }

        
        case "fetch topbar content": 
            return { ...state, topbar: { isLoading: true }}
        case "fetch topbar content succeeded": 
            return { ...state, topbar: { data: action.payload, isLoading: false }}
        case "fetch topbar content failed":
            return { ...state, topbar: { error: action.payload, isLoading: false }}


        case "fetch profile content":
            return { ...state, profile: { isLoading: true }}
        case "fetch profile content succeeded":
            return { ...state, profile: { data: action.payload, isLoading: false }}
        case "fetch profile content failed":
            return { ...state, profile: { error: action.payload, isLoading: false }}

        
        case "fetch global content":
            return { ...state, global: { isLoading: true }}
        case "fetch global content succeeded":
            return { ...state, global: { data: action.payload, isLoading: false }}
        case "fetch global content failed":
            return { ...state, global: { error: action.payload, isLoading: false }}

        
        case "fetch tos content":
            return { ...state, tos: { isLoading: true }}
        case "fetch tos content succeeded":
            return { ...state, tos: { data: action.payload, isLoading: false }}
        case "fetch tos content failed":
            return { ...state, tos: { error: action.payload, isLoading: false }}    


        case "fetch footer content":
            return { ...state, footer: { isLoading: true }}
        case "fetch footer content succeeded":
            return { ...state, footer: { data: action.payload, isLoading: false }}
        case "fetch footer content failed":
            return { ...state, footer: { error: action.payload, isLoading: false }}
        
        
        case "fetch care units content":
            return { ...state, careUnits: { isLoading: true }}
        case "fetch care units content succeeded":
            return { ...state, careUnits: { data: action.payload, isLoading: false }}
        case "fetch care units content failed":
            return { ...state, careUnits: { error: action.payload, isLoading: false }}
        

        case "fetch care units regions content":
            return { ...state, careUnitsRegions: { isLoading: true }}
        case "fetch care units regions content succeeded":
            return { ...state, careUnitsRegions: { data: action.payload, isLoading: false }}
        case "fetch care units regions content failed":
            return { ...state, careUnitsRegions: { error: action.payload, isLoading: false }}


        default:
            return state
    }
}

