import React, { useState, useMemo, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { FindCareUnitView } from './FindCareUnitView'
import { createPath, paths } from '../../utils/urlPaths'
import config from '../../api/config'
import type { FixMeLater } from 'src/new-lib/_types'
import type { HealthcareUnit } from './FindCareUnitView'
import type { RootState } from 'src/store/Model'
import type { CareUnit } from 'src/api/services/ContentAPI/ContentAPIModel'
import { LoadingScreen } from 'src/new-lib/LoadingScreen'


type Props = {
  careUnits: RootState["content"]["careUnits"]
  regions: RootState["content"]["careUnitsRegions"]
  location: FixMeLater
  history: FixMeLater
}

const _FindCareUnitPage = ({ careUnits, regions, location, history }: Props) => {
 

  const urlParams = new URLSearchParams(location.search)
  const hcuType = urlParams.get('type')

  const filteredUnits = useMemo(() => {
    if (hcuType) {
      return careUnits.data.filter(hcu => hcu.type === hcuType)
    }
    return careUnits.data ?? []
  }, [careUnits, hcuType])

  const units = useMemo(() => filteredUnits.map((unit): HealthcareUnit => ({
    id: unit.id,
    hsaid: unit.HsaId,
    name: unit.healthcareunit,
    street: unit.healthcareunitAddress,
    postcode: unit.healthcareunitZipCode,
    postarea: unit.cityname,
    phone: unit.healthcareunitPhoneNumber,
    webAddress: unit.healthcareunitWebAddress,
    careAreas: unit.healthcareAreas,
    type: unit.type,
    region: unit.healthcareunitRegions,
    chat: onChatButtonClick(unit, history)
  })
  ), [filteredUnits])

  const isDistinct = (value: FixMeLater, index: FixMeLater, self: FixMeLater) => self.indexOf(value) === index
  const careAreas = useMemo(() => filteredUnits.flatMap(hcu => hcu.healthcareAreas).filter(isDistinct),
    [filteredUnits])

  const [careAreaState, setCareAreaState] = useState({
    selected: '',
    onSelect: (ev: React.ChangeEvent<HTMLSelectElement>) => {
      setCareAreaState({ ...careAreaState, selected: ev.target.value })
    },
    items: careAreas
  })

  useEffect(() => {
    setCareAreaState({ ...careAreaState, items: careAreas.sort() })
  }, [careAreas])

  const [regionState, setRegionState] = useState({
    selected: '',
    onSelect: (ev: React.ChangeEvent<HTMLSelectElement>) => {
      setRegionState({ ...regionState, selected: ev.target.value })
    },
    items: regions.data
  })

  useEffect(() => {
    setRegionState({ ...regionState, items: regions.data })
  }, [regions])

  if (careUnits.isLoading || regions.isLoading) return <LoadingScreen />
  if (careUnits.error || !careUnits.data) throw new Error("Failed to load care unit")
  if (regions.error || !regions.data) throw new Error("Failed to load regions")


  return (
    <FindCareUnitView
      careAreas={careAreaState}
      regions={regionState}
      careUnits={units}
    />
  )
}

const onChatButtonClick = (unit: CareUnit, history: FixMeLater) => {
  if (unit.isDigitalProvider && unit.isDigitalProviderActive) {
    const url = new URL(config.flow)
    url.searchParams.append('hcuss', createPath(unit.digitalProviderRelativeUri))

    const onClick = () => { window.location.href = url.href }
    return onClick
  }

  if (unit.isDigitalProvider) {
    const url = `${config.capioGoOrigin}${unit.digitalProviderRelativeUri}`
    return url
  }

  if (unit.digitalProviderExternalUri) {
    const onClick = () => {
      history.push({
        pathname: paths["switchboard"],
        search: `?hid=${unit.idExt}`
      })
    }
    return onClick
  }

  return null
}

const mapStateToProps = (state: RootState) => ({
  careUnits: state.content.careUnits,
  regions: state.content.careUnitsRegions
})

export const FindCareUnitPage = withRouter(connect(mapStateToProps)(_FindCareUnitPage))
