import type { RootState } from "src/store/Model"
import type { RootApi } from "src/api/Api"
import { ContentDispatcher } from "./reducer"
import { isAxiosError, isError } from "src/new-lib/_utils"


type Callback = (dispatch: ContentDispatcher, getState: () => RootState, api: RootApi) => Promise<void | undefined>

export const actions = {
    fetchFaq: (): Callback => async (dispatch, getState, api) => {
        const faqContent = getState().content.faq
        if (faqContent.data || faqContent.isLoading)
            return

        dispatch({ type: "fetch faq content" })
        const payload = await api.services.content.getFaqPageContent()

        if (isError(payload))
            dispatch({ type: "fetch flow onboarding start content failed", payload })
        else
            dispatch({ type: "fetch faq content succeeded", payload })
    },

    fetchHome: (): Callback => async (dispatch, getState, api) => {
        const homeContent = getState().content.home
        if (homeContent.isLoading || homeContent.data)
            return

        dispatch({ type: "fetch home content" })
        const payload = await api.services.content.getHomePageContent()


        if (isError(payload))
            dispatch({ type: "fetch home content failed", payload })
        else
            dispatch({ type: "fetch home content succeeded", payload })

    },

    fetchFlowOnboardingStart: (): Callback => async (dispatch, getState, api) => {
        const flowOnboardingContent = getState().content.flowOnboardingStart

        if (flowOnboardingContent.isLoading || flowOnboardingContent.data)
            return

        dispatch({ type: "fetch flow onboarding start content" })

        const payload = await api.services.content.getFlowOnboardingStartPageContent()


        if (isError(payload))
            dispatch({ type: "fetch flow onboarding start content failed", payload })
        else
            dispatch({ type: "fetch flow onboarding start content succeeded", payload })
    },
        
    fetchTopbar: (): Callback => async (dispatch, getState, api) => {    
        const topbarContent = getState().content.topbar

        if (topbarContent.isLoading || topbarContent.data)
            return

        dispatch({ type: "fetch topbar content" })

        const payload = await api.services.content.getTopbarContent()
        

        if (isError(payload))
            dispatch({ type: "fetch topbar content failed", payload })
        else
            dispatch({ type: "fetch topbar content succeeded", payload })
    },

    fetchProfile: (): Callback => async (dispatch, getState, api) => {
        const profileContent = getState().content.profile
        if (profileContent.isLoading || profileContent.data)
            return

        dispatch({ type: "fetch profile content" })
        const payload = await api.services.content.getProfilePageContent()

        if (isError(payload))
            dispatch({ type: "fetch profile content failed", payload })
        else
            dispatch({ type: "fetch profile content succeeded", payload })
    },
    
    fetchGlobal: (): Callback => async (dispatch, getState, api) => {
        const globalContent = getState().content.global
        if (globalContent.isLoading || globalContent.data)
            return

        dispatch({ type: "fetch global content" })
        const payload = await api.services.content.getGlobalContent()

        if (isError(payload))
            dispatch({ type: "fetch global content failed", payload })
        else
            dispatch({ type: "fetch global content succeeded", payload })
    },

    fetchTos: (): Callback => async (dispatch, getState, api) => {
        const tosContent = getState().content.tos
        if (tosContent.isLoading || tosContent.data)
            return

        dispatch({ type: 'fetch tos content' })
        const payload = await api.services.content.getTosContent()

        if (isError(payload))
        dispatch({ type: 'fetch tos content failed', payload })
        
        else dispatch({ type: 'fetch tos content succeeded', payload })
    },

    fetchFooter: (): Callback => async (dispatch, getState, api) => {
        const footerContent = getState().content.footer
        if (footerContent.data || footerContent.isLoading)
            return

        dispatch({ type: "fetch footer content" })
        const payload = await api.services.content.getFooterPageContent()
        if (isError(payload))
            dispatch({ type: "fetch footer content failed", payload })
        else
            dispatch({ type: "fetch footer content succeeded", payload })
    },

    fetchCareUnits: (): Callback => async (dispatch, getState, api) => {
        const careUnitsContent = getState().content.careUnits
        if (careUnitsContent.isLoading || careUnitsContent.data)
            return

        dispatch({ type: "fetch care units content" })
        const payload = await api.services.content.getAllCareUnits()
        
        if (isAxiosError(payload))
            dispatch({ type: "fetch care units content failed", payload })
        else
            dispatch({ type: "fetch care units content succeeded", payload })
    },

    fetchCareUnitsRegions: (): Callback => async (dispatch, getState, api) => {
        const regionsContent = getState().content.careUnitsRegions
        if (regionsContent.isLoading || regionsContent.data)
            return

        dispatch({ type: "fetch care units regions content" })
        const payload = await api.services.content.getCareUnitsRegions()

        if (isAxiosError(payload))
            dispatch({ type: "fetch care units regions content failed", payload })
        else
            dispatch({ type: "fetch care units regions content succeeded", payload })
    }
}
