import { useState } from "react"
import scss from "./OtpPhoneValidationModal.module.scss"
import globalScss from "src/new-lib/_styles/index.module.scss"
import { Typography } from "src/new-lib/Typography"
import { InputOTP } from "src/new-lib/InputOTP"
import { Button } from "src/new-lib/Button"
import { Icon } from "src/new-lib/Icon"
import { EmptyModal } from "src/new-lib/Modal"
import { useDeviceSize } from "src/new-lib/_utils"
import { sleep } from "src/new-lib/_utils"
import { actions as appActions } from "src/store/reducers/app"
import { actions as accountActions } from "src/store/reducers/accounts"
import { Card } from "src/new-lib/Card"

import Loader from 'react-loader-spinner'
import { connect } from "react-redux";
import { analytics } from "src/new-lib/_utils/analytics"


enum ModalCloseAction {
  CloseEnterOtpContent,
  CloseSendSmsContent
}

enum SmsCodeSentAction {
  SendFirstTimeCode,
  SendNewCode
}

type Props = {
  title: string
  infoText: string
  buttonText: string
  onSuccess: () => void
  isOpen: boolean
  onClose: () => void
  dispatchSendPhoneValidationOtp: () => Promise<boolean>
  dispatchVerifyOtp: (otp: string) => Promise<boolean>
  dispatchHasValidatedPhone: () => void
  phoneNumber: string
}

const FeatureComponent = ({
  title,
  infoText,
  buttonText,
  onSuccess,
  isOpen,
  onClose,
  dispatchSendPhoneValidationOtp,
  dispatchVerifyOtp,
  dispatchHasValidatedPhone,
  phoneNumber
}: Props) => {
  const [isFetching, setIsFetching] = useState(false)
  const [otpError, setOtpError] = useState(false)
  const [otpSuccess, setOtpSuccess] = useState(false)
  const [hasSentSmsAtLeastOnce, setHasSentSmsAtLeastOnce] = useState(false)

  const { isMobile } = useDeviceSize();

  const handleClose = (action: ModalCloseAction | null = null) => {
    onClose()
    setIsFetching(false)
    setOtpError(false)
    setOtpSuccess(false)
    setHasSentSmsAtLeastOnce(false)

    handleAnalyticsOnClose(action)
  }

  const handleOnComplete = async (value: string) => {
    setIsFetching(true)

    const isSuccess = await dispatchVerifyOtp(value)
    
    if (isSuccess) {
      setOtpSuccess(true)
      await sleep(1350)

      dispatchHasValidatedPhone()
      onSuccess()

      analytics.track({
        name: 'phone_number_validation',
        category: 'my_profile',
        action: 'verified_code'
      });
    }
    
    else {
      setOtpError(true)
    }

    setIsFetching(false)
  }

  const handleSendPhoneValidationOtp = async (action: SmsCodeSentAction) => {
    setIsFetching(true)
    await dispatchSendPhoneValidationOtp()

    if (!hasSentSmsAtLeastOnce) {
      setHasSentSmsAtLeastOnce(true)
    }

    handleAnalyticsOnSmsSent(action)

    setIsFetching(false)
  }

  const EnterOtpContent = () => (
    <>
      <Typography tag="h2" align="center" margin={{ b: isMobile ? 32: 24 }}>
        Skriv in din kod
      </Typography>
      <div className={scss["otp-container"]}>
        {otpError && !isFetching &&
          <div className={scss["otp-error"]}>
            <Typography tag="p" fontSize="sm" weight="600" color="secondary" margin={{}}>
              Fel kod, vänligen prova igen
            </Typography>
          </div>}
        <InputOTP maxLength={6} onComplete={handleOnComplete} />
      </div>
      <div className={scss["button-container"]}>
        <Button loading={isFetching} expand variant="tertiary" onClick={() => handleSendPhoneValidationOtp(SmsCodeSentAction.SendNewCode)}>
          skicka ny kod
        </Button>
        <Button expand variant="text" onClick={() => handleClose(ModalCloseAction.CloseEnterOtpContent)}>
          avbryt
        </Button>
      </div>
    </>
  )

  const SendSmsContent = () => (
    <>
      <Typography tag="h2" align="center" margin={{ b: isMobile ? 32 : 24 }}>
        {title} {phoneNumber}
      </Typography>
      <Typography tag="p" fontSize="md" align="center" margin={{}}>
        {infoText}
      </Typography>
      <div className={scss["button-container"]}>
        <Button expand onClick={() => handleSendPhoneValidationOtp(SmsCodeSentAction.SendFirstTimeCode)}>
          {buttonText}
        </Button>
        <Button expand variant="text" onClick={() => handleClose(ModalCloseAction.CloseSendSmsContent)}>
          stäng
        </Button>
      </div>
    </>
  )

  return (
    <EmptyModal isOpen={isOpen} handleClose={handleClose}>
      <Card size="md">
        <div className={scss["content"]}>
          <Icon name="mobile" size="xxl" margin={{ b: 44 }} />
          {hasSentSmsAtLeastOnce
            ? <EnterOtpContent />
            : <SendSmsContent />}
          {isFetching && 
            <div className={`${scss["overlay"]} ${scss["overlay-loader"]}`}>
              <Loader type="Oval" color={globalScss.primaryNavy} width={80} height={80} />
            </div>}
          {otpSuccess &&
            <div className={`${scss["overlay"]} ${scss["overlay-success"]}`}>
              <Icon name="completed" size="3xl" />
            </div>}
        </div>
      </Card>
    </EmptyModal>
  )
}

const handleAnalyticsOnClose = (action: ModalCloseAction) => {
  switch(action) {
    case ModalCloseAction.CloseEnterOtpContent:
      analytics.track({
        name: 'phone_number_validation',
        category: 'my_profile',
        action: 'abort_enter_code'
      });
      break;
    case ModalCloseAction.CloseSendSmsContent:
      analytics.track({
        name: 'phone_number_validation',
        category: 'my_profile',
        action: 'abort_sms_confirmation'
      });
      break;
  }
}

const handleAnalyticsOnSmsSent = (action: SmsCodeSentAction) => {
  switch(action) {
    case SmsCodeSentAction.SendFirstTimeCode:
      analytics.track({
        name: 'phone_number_validation',
        category: 'my_profile',
        action: 'send_code'
      });
      break;
    case SmsCodeSentAction.SendNewCode:
      analytics.track({
        name: 'phone_number_validation',
        category: 'my_profile',
        action: 'send_new_code'
      });
      break;
  }
}

const mapStateToProps = (state: any) => ({
  phoneNumber: state.consent.myInformation.fullMobileNumber
})

const mapDispatchToProps = (dispatch: any) => ({
  dispatchSendPhoneValidationOtp: () =>
    dispatch(appActions.sendPhoneValidationOtp()),

  dispatchVerifyOtp: (otp: string) =>
    dispatch(appActions.verifyOtp(otp)).then((result: boolean) => result),

  dispatchHasValidatedPhone: () =>
    dispatch(accountActions.onMyInfoChange("hasValidatedPhone", true))
})

export const OtpPhoneValidationModal = connect(mapStateToProps, mapDispatchToProps)(FeatureComponent)
