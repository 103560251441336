import { Component } from 'react'
import { connect } from 'react-redux'
import config from '../../api/config'
import queryString from 'query-string'
import { FlowScreen } from './FlowScreen'
import { LoadingScreen } from 'src/new-lib/LoadingScreen'
import { paths } from '../../utils/urlPaths'
import { FixMeLater } from 'src/new-lib/_types'
import type { RootState } from 'src/store/Model'

type Props = {
  app: RootState["app"]
  careUnits: RootState["content"]["careUnits"]
  location: FixMeLater
  history: FixMeLater
}

type States = {
  logoutIsInitiated: boolean
}

class Flow extends Component<Props, States> {
  constructor (props: Props) {
    super(props)
    this.state = {
      logoutIsInitiated: false,
    }
  }


  componentDidMount () {
    window.addEventListener('message', this.msgHandler, false)
  }

  msgHandler = (e: MessageEvent) => {
    if (e && e.data) {
      if (e.data === 'flow:logout') {
        this.setState({ logoutIsInitiated: true })
      }
      if (e.data === 'flow:show-unit-list') {
        this.props.history.push(paths["care units"])
      }
    }
  }

  logoutLoadHandler = () => {
    if (this.state.logoutIsInitiated) {
      this.props.history.push("/signout")
    }
  }

  render () {
    const fullCapioURL = getFullCapioUrl(this.props);

    return this.props.careUnits.isLoading ? <LoadingScreen /> : <FlowScreen targetSrc={fullCapioURL} onLoad={this.logoutLoadHandler} />
  }
}

export const FlowPage = connect((state: RootState) => {
  return {
    app: state.app,
    careUnits: state.content.careUnits
  }
})(Flow)

function getFullCapioUrl(props: Props){
  if(props.careUnits.isLoading) {
    return '';
  }

  if (props.careUnits.error || !props.careUnits.data) {
    return config.capioGo;
  }

  const urlQueryParams = queryString.parse(props.location.search)
  const extractSelectedHCU = props.careUnits.data.find((hcu) => hcu.idExt === urlQueryParams.hid)

  if (
    urlQueryParams.hid &&
    extractSelectedHCU &&
    extractSelectedHCU.isDigitalProviderActive
  ) {
    const targetUrl = `${config.capioGoOrigin}${extractSelectedHCU.digitalProviderRelativeUri}/external-apps/login-success?isIframe=true`
    return targetUrl;
  } else {
   return config.capioGo;
  }
}