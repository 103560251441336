import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum';

const DATADOG_CLIENT_TOKEN = 'pub3d7077980d8f53e24a6e134dcaa7c721';
const DATADOG_APPLICATION_ID = 'ece3d18a-86bd-46fe-b0e3-0e854a722f17';

export const initLogging = (environment: 'local' | 'dev' | 'qa' | 'prod') => {
  
    if(environment === 'local') return;

    datadogLogs.init({
        clientToken: DATADOG_CLIENT_TOKEN,
        site: 'datadoghq.eu',
        service: 'capio-online-web',
        env: environment,
        forwardErrorsToLogs: true,
        sessionSampleRate: 100,
    })

    datadogRum.init({
        applicationId: DATADOG_APPLICATION_ID,
        clientToken: DATADOG_CLIENT_TOKEN,
        site: 'datadoghq.eu',
        service: 'capio-online-web',
        env: environment,
        // Specify a version number to identify the deployed version of your application in Datadog
        // Could use commit id in the future for this and calculate it automatically
        version: '1.0.0',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 10,
        defaultPrivacyLevel: 'mask',
        // This risks leaking personal info when for example a user is clicking on a text with their name in it
        trackUserInteractions: false,
        trackResources: true
    })
}
