import type { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios"
import { isAxiosError } from "src/new-lib/_utils"
import { trackAxiosError } from "src/new-lib/_utils/analytics"

export const getAuthHeaders = (token: string) => ({ headers: { Authorization: `Bearer ${token}` }})


type RequestArgs = {
  http: AxiosInstance
  method: "get" | "put" | "post" | "delete" | "patch"
  endpoint: string
  payload?: any
  headers?: AxiosRequestConfig["headers"]
}

export const requestHandler = async <T>(args: RequestArgs): Promise<T | AxiosError> => {
  if (args.method === "get" || args.method === "delete") {
    try {
      const res = await args.http[args.method]<T>(args.endpoint, { headers: args.headers })
      return res.data
    } catch (error) {
      if (isAxiosError(error)) {
        trackAxiosError(error)
        return error
      }
      throw error
    }
  }

  else {
    try {
      const res = await args.http[args.method]<T>(args.endpoint, args.payload, { headers: args.headers })
      return res.data
    } catch (error) {
      if (isAxiosError(error)) {
        trackAxiosError(error)
        return error
      }
      throw error
    }
  }
}
