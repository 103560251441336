import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { ListingSelectView } from './ListingSelectView'
import { getCareCentersSelector } from '../../store/selectors'
import { useHealthcareUnits } from '../_hooks'
import { createPath } from '../../utils/urlPaths'
import { actions as consentActions } from '../../store/reducers/consents'

export const Page = ({ protectedId, regions, centers, history }) => {
  const {
    selectedRegion,
    setRegion,
    filteredHealthcareUnits: filteredCareCenters,
    selectedHealthcareUnit: selectedCareCenter,
    setHealthcareUnit: setCareCenter,
    setSelectionError
  } = useHealthcareUnits({ healthcareUnits: centers })

  const [infoDialogIsOpen, setInfoDialogIsOpen] = useState(false)
  const [protectedIdModalIsOpen, setProtectedIdModalIsOpen] = useState(false)

  const regionIsNotAvailable = (region) => {
    return region && region !== 'Stockholm'
  }

  const onNextButtonClick = () => {
    const currentDate = new Date()
    const targetDate = new Date(2023, 4, 1)

    if (currentDate < targetDate) {
      if (regionIsNotAvailable(selectedRegion)) {
        setInfoDialogIsOpen(true)
      } else if (selectedRegion === 'Stockholm' && !selectedCareCenter) {
        setSelectionError('Du glömde välja vårdcentral.')
      } else if (selectedRegion === 'Stockholm') {
        if (protectedId === 'J') setProtectedIdModalIsOpen(true)
        else {
          const path = createPath({
            name: 'listing > sign (hsaId)',
            params: [
              centers.find(
                (center) => center.healthcareunit === selectedCareCenter
              ).HsaId
            ]
          })
          history.push(path)
        }
      }
    } else {
      window.open('https://listning.1177.se/')
    }
  }

  return (
    <ListingSelectView
      regions={regions}
      selectedRegion={selectedRegion}
      onRegionChange={setRegion}
      careCenters={filteredCareCenters}
      selectedCareCenter={selectedCareCenter}
      onCareCenterChange={setCareCenter}
      onNextButtonClick={onNextButtonClick}
      infoDialogIsOpen={infoDialogIsOpen}
      onInfoDialogClose={() => setInfoDialogIsOpen(false)}
      protectedIdModalIsOpen={protectedIdModalIsOpen}
      onProtectedIdModalClose={() => setProtectedIdModalIsOpen(false)}
    />
  )
}

const mapStateToProps = (state) => ({ 
  regions: state.content.careUnitsRegions.data,
  centers: getCareCentersSelector(state),
  protectedId: state.consent.myInformation.protectedIdentity
})

const mapDispatchToProps = (dispatch) => ({
  dispatchSelectCareCenter: (center) =>
    dispatch(
      consentActions.selectCenter({
        label: center.healthcareunit,
        value: center.healthcareunit,
        idExt: center.idExt,
        region: center.healthcareunitRegions
      })
    )
})

export const ListingSelectPage = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Page)
)
