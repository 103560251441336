import React, { Component } from 'react'
import { Icon } from '../../../lib/Icon'
import { connect } from 'react-redux'
import config from '../../../api/config'
import { actions } from '../../../store/reducers/consents'
import { paths } from '../../../utils/urlPaths'
import { SelectCareUnitView } from '../../SeekCare'
import { MenuItem } from '../../../lib/MenuList'

class HealthCareUnitSelect extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: false,
      loadingSkipSelection: false
    }
  }

  getNumberOfUnitsForRegion = () => {
    return this.props.centers && this.sortHealthCareUnit(this.props.centers).length
  }

  sortHealthCareUnit = (hcus) => {
    const filterUnits = hcus.filter(huc => huc.region === this.props.selectedSwitchBoardRegion)

    return filterUnits.sort((a, b) => {
      if (a.name < b.name) { return -1 }
      if (a.name > b.name) { return 1 }
      return 0
    })
  }

  isSaveMode = () => {
    return this.props.location.state && this.props.location.state.saveMode
  }

  saveEnterHealthCareUnit = (hcu) => {
    if (hcu.isDigitalProvider && !hcu.isDigitalProviderActive && !this.isSaveMode()) {
      this.props.dispatch(actions.selectSwitchBoardHCU(hcu.idExt))
      this.props.dispatch(actions.updateAssociatedCareGivers())
        .then(() => {
          this.setState({ loading: false })
          this.props.history.push(paths["switchboard"])
        })
        .catch(() => {
        })
      return
    }

    if (!hcu.isDigitalProvider && hcu.digitalProviderExternalUri && !this.isSaveMode()) {
      this.props.dispatch(actions.selectSwitchBoardHCU(hcu.idExt))
      this.props.dispatch(actions.updateAssociatedCareGivers())
        .then(() => {
          this.setState({ loading: false })
          this.props.history.push(paths["switchboard"])
        })
        .catch(() => {
        })

      return
    }

    if (!hcu.isDigitalProvider && !this.isSaveMode()) {
      this.props.dispatch(actions.selectSwitchBoardHCU(hcu.idExt))
      this.props.dispatch(actions.updateAssociatedCareGivers())
        .then(() => {
          this.setState({ loading: false })
          this.props.history.push(paths["switchboard"])
        })
        .catch(() => {
        })
      return
    }

    this.props.dispatch(actions.selectSwitchBoardHCU(hcu.idExt))
    this.props.dispatch(actions.updateAssociatedCareGivers())
      .then(() => {
        if (this.isSaveMode()) {
          this.props.history.push(paths["chat > settings"], { reloadMinimumState: true, showDialog: true })
        } else {
          const url = new URL(config.flow)
          url.searchParams.append('hcuss', this.props.persistedCenter.digitalProviderRelativeUri)
          window.location = url
        }
      })
      .catch(() => {
        this.setState({ loading: false })
      })
  }

  enterHealthCareUnit = (hcu) => {
    if (!hcu.isDigitalProvider && hcu.digitalProviderExternalUri) {
      this.props.history.push({
        pathname: paths["switchboard > external chat"],
        search: this.props.location.search,
        state: { idExt: hcu.idExt }
      })
      return
    }
    if (!hcu.isDigitalProvider) {
      this.props.history.push({
        pathname: paths["switchboard > unit without chat"],
        search: this.props.location.search,
        state: { idExt: hcu.idExt }
      })
      return
    }
    if (!hcu.isDigitalProviderActive) {
      this.props.history.push({
        pathname: paths["switchboard > non-integrated unit (obsolete?)"],
        search: this.props.location.search,
        state: { idExt: hcu.idExt }
      })
      return
    }
    const url = new URL(config.flow)
    url.searchParams.append('hcuss', hcu.digitalProviderRelativeUri)
    window.location = url
  }

  onClickSkipSelection = () => {
    this.setState({ loadingSkipSelection: true })
    window.location = config.flow
  }

  onClickSelectHCU = (hcu) => {
    this.setState({ loading: true })
    if (hcu.type === 'vardcentral') {
      this.saveEnterHealthCareUnit(hcu)
    } else if (hcu.type === 'specialist') {
      this.enterHealthCareUnit(hcu)
    }
  }

  generateFilteredHealthCareUnits = () => {
    return this.sortHealthCareUnit(this.props.centers).map((d) =>
      <MenuItem
        style={{ height: '40px', width: '100%' }}
        key={d.name}
        text={d.name}
        divider
        onClick={() => this.onClickSelectHCU(d)}
        iconLeft={null}
        iconRight={<Icon size='sm' color='gray' name='arrow-right' />}
      />
    )
  }

  getCareUnits = () => {
    const filteredCareUnits = this.props.healthcareUnits.filter(c => c.healthcareunitRegions === this.props.selectedSwitchBoardRegion)

    return filteredCareUnits.map(unit => ({
      id: unit.id,
      hsaid: unit.HsaId,
      name: unit.healthcareunit,
      street: unit.healthcareunitAddress,
      postcode: unit.healthcareunitZipCode,
      postarea: unit.cityname,
      phone: unit.healthcareunitPhoneNumber,
      webAddress: unit.healthcareunitWebAddress,
      careAreas: unit.healthcareAreas,
      type: unit.type,
      region: unit.healthcareunitRegions,
      onClick: () => this.onClickSelectHCU(unit),
      // Map the rest of the data
      healthcareunitLatitude: unit.healthcareunitLatitude,
      healthcareunitLongitude: unit.healthcareunitLongitude,
      idExt: unit.idExt,
      isDigitalProvider: unit.isDigitalProvider,
      isDigitalProviderActive: unit.isDigitalProviderActive,
      isPatientListable: unit.isPatientListable,
      isbvc: unit.isbvc,
      videoCallDelegation: unit.videoCallDelegation
    }))
  }

  render () {
    return (
      <SelectCareUnitView
        region={this.props.selectedSwitchBoardRegion}
        careUnits={this.getCareUnits()}
        onClickRegionSelect={() => this.props.history.push(paths["switchboard > select region"])}
      />

    )
  }
}

export const HealthCareUnitSelectPage = connect(state => ({
  healthcareUnits: state.content.careUnits.data
}))(HealthCareUnitSelect)
