import type { TosDispatcher } from "./reducer";
import type { RootApi } from "src/api/Api";
import type { RootState } from "src/store/Model";
import { isError } from "src/new-lib/_utils";


export const actions = {
  fetchLatestTos() {
    return async (dispatch: TosDispatcher, getState: () => RootState, api: RootApi) => {
      const { app, termsOfServices } = getState();
      const latestTos = termsOfServices.latestTos;

      if (latestTos.data || latestTos.isLoading) return

      dispatch({ type: "fetch latest tos" });
      const payload = await api.services.account.getLatestTos(app.sessionToken)
        
      if (isError(payload))
        dispatch({ type: "fetch latest tos failed", payload })
      else
        dispatch({ type: "fetch latest tos succeeded", payload })
    };
  },

  fetchTosStatus() {
    return async (dispatch: TosDispatcher, getState: () => RootState, api: RootApi) => {
      const { app, termsOfServices } = getState()
      const tosStatus = termsOfServices.userTosStatus;
      

      if (tosStatus.data || tosStatus.isLoading) return


      dispatch( {type: "fetch tos status"} );
      const payload = await api.services.account.getTosStatus(app.sessionToken)

      if (isError(payload))
        dispatch({ type: "fetch tos status failed", payload })
      else
        dispatch({ type: "fetch tos status succeeded", payload: payload.status })
    }
  },

  fetchTosAgreement() {
    return async (dispatch: TosDispatcher, getState: () => RootState, api: RootApi) => {
      const { app } = getState()

      dispatch( {type: "fetch tos agreement"} );
      const payload = await api.services.account.acceptLatestTos(app.sessionToken)

      if (isError(payload))
        dispatch({ type: "fetch tos agreement failed", payload })
      else
        dispatch({ type: "fetch tos agreement success", payload })
    }
  }
};