import React, { useState, useMemo } from "react";
import { ProfileChatSettingsScreen } from "./ProfileChatSettingsScreen";
import { connect } from "react-redux";
import { getCareCentersSelector } from "../../store/selectors";
import { actions } from "../../store/reducers/consents/index";
import { useHealthcareUnits } from "../_hooks"; 


const Page = ({ regions, centers, savedCenterId, dispatchSelectedCenterId, dispatchSaveCenterSelection }) => {
    const [submitting, setSubmitting] = useState(false);
    const onSaveComplete = () => {
        setTimeout(() => {
            setSubmitting(false);
            setShowConfirmDialog(true);
        }, 1000);
    }

    const { 
        selectedRegion,
        setRegion,
        selectedHealthcareUnit,
        initialHealthcareUnit,
        setHealthcareUnit,
        filteredHealthcareUnits
    } = useHealthcareUnits({ healthcareUnits: centers, idExtOfInitialHcu: savedCenterId });


    const [formError, setFormError] = useState("");
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);


    const newCenter = useMemo(() => centers.find(c => c.healthcareunit === selectedHealthcareUnit), [selectedHealthcareUnit]);

    const onSave = () => {

        const careCenterHasBeenTouched = selectedHealthcareUnit !== initialHealthcareUnit;

        if (!careCenterHasBeenTouched) {
            setFormError("Du har inte gjort några ändringar.")
        }
        else if (!selectedHealthcareUnit) {
            setFormError("Du glömde välja vårdcentral.");
        }
        else {
            setFormError("");

            
            dispatchSelectedCenterId(newCenter.idExt);

            setSubmitting(true);
            dispatchSaveCenterSelection()
                .then(onSaveComplete)
                .catch(onSaveComplete);
        }

    }

    return (
        <ProfileChatSettingsScreen
            regions={regions}
            careCenters={filteredHealthcareUnits}
            selectedRegion={selectedRegion}
            onRegionChange={setRegion}
            selectedCareCenter={selectedHealthcareUnit}
            onCareCenterChange={setHealthcareUnit}
            onSaveButtonClick={onSave}
            formError={formError}
            submitting={submitting}
            showConfirmDialog={showConfirmDialog}
            dialogListingHealthcareUnit={newCenter}
            onConfirmDialogCloseButtonClick={() => setShowConfirmDialog(false)}
        />);
}

const mapStateToProps = (state) => ({
    regions: state.content.careUnitsRegions.data,
    centers: getCareCentersSelector(state),
    savedCenterId: state.consent.myInformation.primaryHealthCareEntityId,
});

const mapDispatchToProps = dispatch => ({
    dispatchSelectedCenterId: idExt => dispatch(actions.selectSwitchBoardHCU(idExt)),
    dispatchSaveCenterSelection: () => dispatch(actions.updateAssociatedCareGivers()),
});

export const ProfileChatSettingsPage = connect(mapStateToProps, mapDispatchToProps)(Page);
